<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" @input="close()" max-width="600px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">Terms of Use</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 pt-4 pb-3 px-5>
                    <v-row>
                        <v-col cols="12">
                            <div style="max-height: 250px; overflow: auto;" v-html="tou" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions">
                <v-btn text outlined class="px-5" @click.native="close()">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="px-10" @click.native="$emit('agreed')">I Agree</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import tou from '@/data/tou'

export default {

    name: 'TOUModal',
    props: {
    },
    computed: {
    },
    data: () => ({
        tou: tou
    }),
    methods: {
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
