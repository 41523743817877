export default `
    <p>
								I&rsquo;mOkay.me("I&rsquo;m Okay", "I&rsquo;m Okay App", “we”, “us” or “our”) agrees to furnish services to the Subscriber (the “Subscriber”, "client", “you” or “your”), subject to the following Terms of Service. Use of I&rsquo;mOkay.me's service constitutes acceptance and agreement to I&rsquo;mOkay.me's
								Terms of Service (this document).
							</p>

							<p>
								I&rsquo;mOkay.me reserves the right to modify the Terms of Service without notice. Latest date of this agreement can be found underneath the heading at the top of this document.
							</p>

							<h4>Use of Services</h4>

							<p>
								You may use our services, provided that you are of legal age to form a binding contract and are not barred from receiving such services under the laws of the United States or other jurisdictions. In order to access our services, you are required to provide
								current and factual identification, contact, and other information as part of the registration process. You are responsible for the confidentiality of your account information and for all activities that occur under your
								account. You are solely responsible for all content within your account. You agree to immediately notify I&rsquo;mOkay.me of any unauthorized use of your account or any other breach of security. I&rsquo;mOkay.me will not be liable for any
								loss or damage as a result of your failure to provide us with accurate information or to keep your account secure.
							</p>

							<h4>SMS/Text Message Fees</h4>

							<p>
								I&rsquo;mOkay.me is not responsible for any fees imposed by your network provider or carrier for sending and receiving SMS (text) messages. Please review your contract with your service provider before agreeing to send or receive SMS/text messages from this service.
							</p>

							<h4>Acceptable Conduct</h4>

							<p>
								You are responsible for any data that you provide to your account while using I&rsquo;mOkay.me. You will not engage in any activity that interferes with or disrupts I&rsquo;mOkay.me's services
								or networks connected to I&rsquo;mOkay.me.
							</p>

							<h4>Prohibited Usage</h4>

							<p>
								You agree that any of the below activities are considered prohibited usage and will result in immediate account suspension or cancellation without a refund and the possibility that I&rsquo;mOkay.me will impose fees; and/or pursue civil remedies without providing
								advance notice.
							</p>

							<p>
								<u>Misuse of System Resources</u>: Intentional misuse of system resources, including but not limited to employing programs that consume excessive network capacity, CPU cycles, or disk IO.
							</p>

							<p>
								<u>Excessive SMS Messaging</u>: Intentional misuse of system functionality to transmit text messages in excess of the typical amount of 10 transactions per day.
							</p>

							<p>
								<u>Access to Other Computers or Networks without Authorization</u>: Attempting unauthorized and/or illegal access of computers, networks and/or accounts not belonging to party seeking access. Any act which interferes with
								the services of another user or network. Any act relating to the circumvention of security measures.
							</p>

							<p>
								I&rsquo;mOkay.me and the services it provides may only be used for lawful purposes. Transmission, distribution, or storage of any information, data or material in violation of United States or state regulation or law, or by the common law, is prohibited. This
								includes, but is not limited to, material protected by copyright, trademark, trade secret, or other intellectual property rights. I&rsquo;mOkay.me's services may not be used to facilitate infringement of these laws in any way.
							</p>

							<p>
								<u>Other Activities viewed as Illegal or Harmful</u>: Engaging in illegal activities or engaging in activities harmful to the operations of I&rsquo;mOkay.me or I&rsquo;mOkay.me's customers.
							</p>

							<p>
								<u>Providing False Data on any Contract or Application</u>: including fraudulent use of credit card numbers.
							</p>

							<h4>Invoicing And Payment</h4>

							<p>
								You agree that I&rsquo;mOkay.me shall be permitted to charge your credit card on a monthly, annual, or other agreed upon basis in advance of providing services. Payment is due upon invoicing. Service may be interrupted on accounts that reach your subscriptions payment-due date.
							</p>

							<p>
								Subscriber is aware that I&rsquo;mOkay.me may prospectively change the specified rates and charges from time to time, but only for new subscriptions. Your subscription price will not be increased without your knowledge and consent.
							</p>

							<p>
								I&rsquo;mOkay.me is not responsible for any additional bank fees, interest charges, finance charges, over draft charges, or other fees resulting from charges billed by I&rsquo;mOkay.me. Currency exchange settlements will be based on agreements between you and the provider
								of your credit card. Pro-rated credits will not be issued for unused services when customer retains any active service.
							</p>

							<h4>Support Boundries</h4>

							<p>
								I&rsquo;mOkay.me, provides technical support to our subscribers via our e-mail support system. The following are our guidelines when providing support: I&rsquo;mOkay.me provides support related to your I&rsquo;mOkay.me created account functioning as expected. I&rsquo;mOkay.me does
								not offer technical support for application specific issues such as application configuration, programming, web or mail server configuration, or any other such issue.
							</p>

							<h4>Account Cancellation Or Suspension</h4>

							<p>
								I&rsquo;mOkay.me reserves the right to suspend network access to any customer if, in the judgment of the I&rsquo;mOkay.me network administrators, the customer's server is the source or target of a violation of any of the other terms of service or for any other reason which
								I&rsquo;mOkay.me chooses. I&rsquo;mOkay.me will use reasonable care in notifying the Customer and in resolving the problem in a method resulting in the least amount of service interference. I&rsquo;mOkay.me reserves the right to terminate service
								without notice for continued and repeated violations of the terms of service. If inappropriate activity is detected, all accounts of the Customer in question will be deactivated until an investigation is complete. Prior
								notification to the Customer is not assured. In extreme cases, law enforcement will be contacted regarding the activity. The customer will not be credited for the time the customer's machines were suspended.
							</p>

							<p>
								If at any time it becomes necessary for I&rsquo;mOkay.me to cancel a customer's service without cause, I&rsquo;mOkay.me will provide 10 days advance notice.
							</p>

							<p>
								You may cancel the service at any time by using the "Cancel Subscription" link located on the "Subscription" sub-tab of the I&rsquo;mOkay.me web application. Cancellation of service does not relieve Subscriber of responsibility for the payment of all accrued charges.
							</p>

							<h4>Disclosure To Law Enforcement</h4>

							<p>
								The Terms of Service specifically prohibits the use of our service for illegal activities. Therefore, Subscriber agrees that I&rsquo;mOkay.me may disclose any and all subscriber information including assigned IP numbers, account history, account use, etc. to any
								court who sends us a valid Court Order, without further consent or notification to the Subscriber. In addition, I&rsquo;mOkay.me shall have the right to terminate all service set forth in this Agreement.
							</p>

							<h4>Server Security</h4>

							<p>
								Subscriber is solely responsible for maintaining the security of Subscriber's servers that are created via the I&rsquo;mOkay.me service. I&rsquo;mOkay.me shall have no liability for any security breaches or data breaches of Subscriber's servers, even if those servers were
								initially created and provisioned by I&rsquo;mOkay.me. I&rsquo;mOkay.me does not gurantee the security of any of Subscriber's servers, even if those servers were initially created and provisioned by I&rsquo;mOkay.me.
							</p>

							<h4>Backup</h4>

							<p>
								Subscriber is solely responsible for the preservation of Subscriber's data which Subscriber saves onto their account. Even with respect to Data as to which Subscriber contracts for backup services provided by I&rsquo;mOkay.me, I&rsquo;mOkay.me shall have
								no responsibility to preserve Data. I&rsquo;mOkay.me shall have no liability for any data that may be lost.
							</p>

							<h4>Warranty Disclaimer</h4>

							<p>
								We have no special relationship with or fiduciary duty to you. You acknowledge that we have no duty to take any action regarding: which Subscribers gain access to the Services; what Content you access via the Services; or how you may interpret or use
								the Content.
							</p>

							<p>
								You release us from all liability for you having acquired or not acquired Content through the Services. We make no representations concerning any Content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy,
								copyright compliance, or legality of material or Content contained in or accessed through the Services.
							</p>

							<p>
								THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
								AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT:
								(I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES OR OTHER
								HARMFUL COMPONENTS; OR (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK.
							</p>

							<h4>Limitation Of Liability</h4>

							<p>
								IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS,
								DATA LOSS, DATA THEFT, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER
								ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF FEES PAID TO US FOR THE PARTICULAR SERVICES
								DURING THE IMMEDIATELY PREVIOUS ONE MONTH PERIOD, EVEN IF I&rsquo;mOkay.me HAD BEEN ADVISED OF, KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY THEREOF. SUBSCRIBER ACKNOWLEDGES THAT THE FEES PAID BY HIM OR HER REFLECT THE ALLOCATION
								OF RISK SET FORTH IN THIS AGREEMENT AND THAT I&rsquo;mOkay.me WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS. SUBSCRIBER HEREBY WAIVES ANY AND ALL CLAIMS AGAINST I&rsquo;mOkay.me ARISING OUT OF SUBSCRIBER'S PURCHASE OR USE
								OF THE SERVICES, OR ANY CONDUCT OF I&rsquo;mOkay.me’S DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR REPRESENTATIVES. YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICES OR ANY OTHER GRIEVANCE SHALL
								BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO OR USE OF THE SERVICES.
							</p>

							<h4>Indemnity</h4>

							<p>
								I&rsquo;mOkay.me wishes to emphasize that in agreeing to the I&rsquo;mOkay.me Terms of Service, customer indemnifies I&rsquo;mOkay.me for any violation of the Terms of Service that results in loss to I&rsquo;mOkay.me or the bringing of any claim against I&rsquo;mOkay.me by any third-party. This
								means that if I&rsquo;mOkay.me is sued because of a customer's or a customer of a customer's activity, the customer will pay any damages awarded against I&rsquo;mOkay.me, plus all costs and reasonable attorney's fees.
							</p>

							<h4>Notice</h4>

							<p>
								You agree that I&rsquo;mOkay.me may provide you with notices, including those regarding changes to the Terms of Service, by email, regular mail, or postings on I&rsquo;mOkay.me services.
							</p>

							<h4>Entire Agreement</h4>

							<p>
								The Terms of Service (including any policies, guidelines or amendments that may be presented to you from time to time) constitute the entire agreement between you and I&rsquo;mOkay.me and govern your use of I&rsquo;mOkay.me services, superceding any prior agreements between
								you and I&rsquo;mOkay.me for the use of I&rsquo;mOkay.me services.
							</p>

							<h4>Choice Of Law And Forum</h4>

							<p>
								The Terms of Service and the relationship between you and I&rsquo;mOkay.me shall be governed by the laws of the State of Arkansas without regard to its conflict of law provisions. You and I&rsquo;mOkay.me agree to submit to the personal and exclusive jurisdiction of the
								courts located within the county of Saline, Arkansas.
							</p>

							<h4>Waiver And Severability Of Terms</h4>

							<p>
								The failure of I&rsquo;mOkay.me to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. If any provision of the Terms of Service is found by a court of competent jurisdiction to be invalid,
								the parties nevertheless agree that the court should endeavor to give effect to the party's intentions as reflected in the provision, and the other provisions of the Terms of Service remain in full force and effect.
							</p>

							<h4>Statute Of Limitations</h4>

							<p>
								You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of I&rsquo;mOkay.me services or the Terms of Service must be filed within one (1) year after such claim or cause of action arose or be
								forever barred.
							</p>
`